import { marketPlace } from '@/axios'

export default {
  addNewDiscount (payload) {
    return marketPlace().post('merchant/discounts/coupons', payload)
  },
  editDiscount (id, payload) {
    return marketPlace().patch(`merchant/discounts/${id}`, payload)
  },
  getDiscount (id) {
    return marketPlace().get(`merchant/discounts/${id}`)
  }
}
